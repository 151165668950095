"use client";

/**
 * Third-party libraries.
 */
import { Editor } from "@tiptap/core";
import { useEffect, useRef, useState } from "react";

/**
 * Project components.
 */
import { CommunicationLogStatus } from "@/components/client/communication-log";
import { useCallUpdateSummaryMutation } from "@/components/client/graphql";
import { useClickOutside } from "@/components/client/hooks/use-click-outside";
import { RichTextEditor } from "@/components/client/rich-text/rich-text-editor";

/**
 * Props for the CallSummaryCard component.
 */
type CallSummaryCardProps<V extends boolean = false> = {
  /**
   * The data of the call.
   */
  data: {
    /**
     * The Twilio Call SID.
     */
    callSid: string;
    /**
     * The summary of the call.
     */
    summary?: string;
    /**
     * Call Status
     *
     * In live state, the default mode is 'Edit'; otherwise, it's 'use previous' or 'view.
     */
    callStatus?: CommunicationLogStatus;
  };
  /**
   * Determines if the card is disabled.
   */
  disabled?: boolean;
  /**
   * Determines if the card is visible.
   */
  visible?: boolean;
};

/**
 * A card with a text area to input a summary about a call.
 */
export function CallSummaryCard({
  data,
  disabled = false,
  visible,
}: CallSummaryCardProps) {
  const { callSid, summary = "", callStatus } = data;
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  /**
   * Serves as the final storage of the call summary. This is only updated when
   * the "save" button is clicked.
   */
  const [callSummary, setCallSummary] = useState<string>(summary);

  /**
   * The content of the call summary editor. This won't be committed until the
   * save button is clicked.
   *
   * This serves as a temporary storage for the editor content. This is reverted
   * when the "cancel" button is clicked.
   */
  const [editorContent, setEditorContent] = useState<string>(summary);

  /**
   * Controls if the call summary is currently being edited or not.
   */
  const [isEditing, setIsEditing] = useState(false);

  /** The tiptap editor. */
  const editorRef = useRef<Editor | null>(null);

  /** The ref for the editor container. When clicked outside, will cancel editing. */
  const editorContainerRef = useClickOutside({
    handler: cancelEditing,
  });

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [__updateCallSummary, { loading: updatingCallSummary }] =
    useCallUpdateSummaryMutation();

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /**
   * Updates the call summary and sets the state.
   */
  const updateCallSummary = () => {
    if (updatingCallSummary) return;

    console.log("[updating] editorContent", editorContent);

    __updateCallSummary({
      variables: {
        input: {
          callSid,
          summary: editorContent ?? null,
        },
      },
    }).then((result) => {
      const newCallSummary = result.data?.callUpdateSummary?.summary ?? "";
      setIsEditing(false);
      setCallSummary(newCallSummary);
      // setEditorContent(newCallSummary);
    });
  };

  // ===========================================================================
  // ===========================================================================
  // Functions
  // ===========================================================================
  // ===========================================================================

  /** Starts editing the call summary. */
  function startEditing() {
    setIsEditing(true);

    // Focus on the editor at the end of the text.
    editorRef.current?.commands.focus("end");

    // Alternatively: Focus on the editor and highlight everything.
    // editorRef.current?.commands.focus("all");
  }

  /** Cancels editing the call summary. */
  function cancelEditing() {
    setEditorContent(callSummary);
    setCallSummary(callSummary);
    editorRef?.current?.commands?.setContent(callSummary);
    setIsEditing(false);
  }

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  /**
   * Whenever `summary` prop changes. (i.e. selected a different communication log).
   */
  useEffect(() => {
    setCallSummary(summary);
    setEditorContent(summary);
    setIsEditing(false);
  }, [disabled, summary]);

  /** Whenever call status changes to 'Wrapup' or 'Live' status, start in edit mode. */
  useEffect(() => {
    if (callStatus === CommunicationLogStatus.ONGOING) {
      startEditing();
    }
  }, [callStatus, callSid]);
  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!visible) {
    return null;
  }

  return (
    <div className="flex animate-slide-left flex-col items-start justify-start self-stretch rounded-md border border-slate-200 bg-white">
      <div
        className="inline-flex items-center justify-between self-stretch border-b border-slate-200 p-4"
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <div className="text-sm font-semibold leading-[17.50px] text-tpl-navy">
          Call Summary
        </div>

        <div>
          <span className="text-xs text-neutral-400">
            {updatingCallSummary ? "Saving..." : ""}
          </span>
        </div>
      </div>

      <div className="flex flex-col items-start justify-start gap-4 self-stretch p-4">
        <div
          ref={editorContainerRef}
          className={`w-full border transition-all hover:bg-neutral-light-grey ${disabled ? "" : ""} ${isEditing ? "rounded-md border border-semantic-blue bg-neutral-light-grey" : "border-transparent"}`}
          onClick={() => {
            if (!isEditing) {
              startEditing();
            }
          }}
        >
          <RichTextEditor
            onCreate={({ editor }) => {
              editorRef.current = editor;
            }}
            onHotkey_ENTER={() => {
              updateCallSummary();
            }}
            onHotkey_ESC={() => {
              editorRef?.current?.commands.blur();
              cancelEditing();
            }}
            placeholder="No call summary"
            content={callSummary}
            editable={() => {
              return disabled ? false : isEditing;
            }}
            onUpdate={({ editor }) => {
              const value = editor.getHTML();
              setEditorContent(value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
